import React, { Fragment, memo, useEffect, useState } from 'react';
import { Button, Container, Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { JobService } from 'services/Employer/Listing/Job';
import { ReducerType } from 'redux/reducers';
import Client from './AddClient';
import AddNewJob from './AddEditJob';
import CreateProject from './AddProject';
import { useModal } from 'components/Modals';
import {
  setCandidatesReducer,
  setDeclinedCandidatesReducer,
  setJobListReducer,
  setJobOfListingReducer,
  getAllCandidateList,
} from 'redux/reducers/Employer/actionTypes';
import {
  getJobList, getSetting
} from "redux/reducers/Employer/actionTypes";
import SignOrgTerm from "./SignOrgTerms";


interface DashboardProps {}

const Myjobs = memo((props: DashboardProps) => {
  const dispatch = useDispatch();
  const { jobList, interviewOffers, jobOffers } = useSelector((state: ReducerType) => state.employer);
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [data, setData] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [isProject, setIsProject] = useState(false); 
  const [showSignTermModal, setShowSignTermModal] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      // console.log("settings", setting)
      const filteredJobs = jobList
        .filter(item => !item.isArchived)
        .sort((a, b) => b.id - a.id)
        .map(job => {
          const interviewCount = interviewOffers.filter(offer => offer.job === job.id).length;
          const contractCount = jobOffers.filter(offer => offer.job === job.id).length;
          return {
            ...job,
            interview: interviewCount,
            contract: contractCount
          };
        });
      setData(filteredJobs);
      setJobsLoaded(true);
    }
  }, [jobList, interviewOffers, jobOffers]);
  
  const handleUpgrade = ({ data }) => {
    const jobId = data.id;
  
    if (setting?.terms) {
      // console.log("This is the jobId we are sending", jobId);
      openUpgrade({ jobId: jobId }); // Pass the jobId directly
    } else {
      setCurrentJobId(jobId); // Set the state
      // console.log("setting current job to", jobId);
      setShowSignTermModal(true);
    }
  };

  const { onOpen: openUpgrade } = useModal('Payment');
  const handleAcceptTerm = async () => {
    // console.log("after accepted what is it", currentJobId);
    setShowSignTermModal(false);
    openUpgrade({ jobId: currentJobId }); // Ensure the correct value of currentJobId is passed
  };
  const handleShowSignTermModal = () => {
    setShowSignTermModal(false);
    handleClose()
  };
  
  const handleClose = async () => {
    try {
      dispatch(setLoading(true));
      await JobService.putJob({ listings: false }, currentJobId);
      dispatch(setLoading(false));
      dispatch(getJobList());
    } catch (error) {
      dispatch(setErrorMess("There was an error cancelling the job listing."));
      dispatch(setLoading(false));
    }
  };

  const handleAddJob = async (data) => {
    const jobData = data?.jobData 
    try {
      dispatch(setLoading(true));
      let jobResponse = null;
      let shouldPoll = false;
  
      // Post the job and wait for the response
      const res = await JobService.postJob(jobData);
      jobResponse = res;
  
      // Dispatch actions based on the response
      dispatch(setJobOfListingReducer(res));
      dispatch(setJobListReducer([...jobList, res]));
  
      const isAssistUser = user.role === 7;
      const isPaidUser = setting?.headHunter === true;
      shouldPoll = isAssistUser || isPaidUser;
      if (data && Array.isArray(data.data.questions) && data.data.questions.length > 0) {
        // Prepare the payload with jobId and the array of questions
        console.log("triggered")
        const payload = {
          jobId: res.id, // Use the job ID from the response
          questions: data.data.questions.map((questionItem) => ({
            question: questionItem.question,
            type: questionItem.type,
          })),
        };
      
        // Invoke JobService.postQuestions with the payload
        JobService.postQuestions(payload)
          .then((response) => {
            // Handle the successful response if needed
            console.log('Questions saved:', response);
          })
          .catch((error) => {
            // Handle any errors that occur during the request
            console.error('Error saving questions:', error);
          });
      }
  
      if (shouldPoll && jobData?.listings === true) { 
        // console.log("it went through and posted");
  
        const send = { job_id: res.id, region: jobData?.region?.toLowerCase() };
  
        // Trigger the headhunter function and handle errors
        try {
          await JobService.headHuntertrigger(send);
        } catch (e) {
          console.error("Error triggering headhunter:", e);
        }


  
        // Check data.outreach for each platform and add to body if length > 1
        // console.log("here", data);
        const body: { job_id: any; [key: string]: any } = { job_id: res.id };
        
        // Check if the social media content properties exist in `data` and have a length greater than 1
        if (data?.facebook_content && data.facebook_content.length > 1) {
          body.facebook = data.facebook_content;
        }
        if (data?.twitter_content && data.twitter_content.length > 1) {
          body.twitter = data.twitter_content;
        }
        if (data?.instagram_content && data.instagram_content.length > 1) {
          body.instagram = data.instagram_content;
        }
        if (data?.linkedin_content && data.linkedin_content.length > 1) {
          body.linkedin = data.linkedin_content;
        }
        
        // Trigger GitHub action and post on social media
        triggerGitHubAction();
        JobService.postSocial(body); // Pass the body object to postSocial
  
        const isRedirectToListingJob = true; // Set this based on your logic
        const assist = true; // Set this based on your logic
        getAllCandidates(res, isRedirectToListingJob, assist);
  
      } else if (jobData?.listings === true) {
        // console.log("it should have triggered handleUpgrade");
        handleUpgrade({ data: { id: res.id } });
      }
  
    } catch (e) {
      console.error("Error posting job:", e);
    } finally {
      dispatch(setLoading(false));
    }
  };
  

  const handleUpdateJob = (data, id) => {
    // console.log("data", data)
    const jobData = data?.jobData 
    dispatch(setLoading(true));
    let shouldPoll = false;
    let jobResponse = null;
  
    JobService.putJob(jobData, id)
      .then(async (res) => {
        jobResponse = res;
        dispatch(setJobOfListingReducer(res));
        const index = jobList?.findIndex((item) => item?.id === res?.id);
        let headHunterStatus;
        const isAssistUser = user.role === 7;
        const isPaidUser = setting?.headHunter === true;
        shouldPoll = isAssistUser || isPaidUser;
        // console.log("questions", data.data.questions)
        if (data && Array.isArray(data.data.questions) && data.data.questions.length > 0) {
          // Prepare the payload with jobId and the array of questions
          // console.log("triggered")
          const payload = {
            jobId: res.id, // Use the job ID from the response
            questions: data.data.questions.map((questionItem) => ({
              question: questionItem.question,
              type: questionItem.type,
            })),
          };
        
          // Invoke JobService.postQuestions with the payload
          JobService.postQuestions(payload)
            .then((response) => {
              // Handle the successful response if needed
              console.log('Questions saved:', response);
            })
            .catch((error) => {
              // Handle any errors that occur during the request
              console.error('Error saving questions:', error);
            });
        }
  
        if (index !== -1) {
          const job = jobList[index];
          headHunterStatus = job?.headhunter;
  
          if ((shouldPoll && jobData?.listings === true)) {
            const send = { job_id: id, region: job?.region?.toLowerCase() };
            await JobService.headHunterUpdate(send, id).catch(e => {
              console.error("Error triggering headhunter:", e);
            });
            await JobService.headHuntertrigger(send).catch(e => {
              console.error("Error triggering headhunter:", e);
            });
            // Check data.outreach for each platform and add to body if length > 1
            // console.log("here", data);
            const body: { job_id: any; [key: string]: any } = { job_id: res.id };
            
            // Check if the social media content properties exist in `data` and have a length greater than 1
            if (data?.facebook_content && data.facebook_content.length > 1) {
              body.facebook = data.facebook_content;
            }
            if (data?.twitter_content && data.twitter_content.length > 1) {
              body.twitter = data.twitter_content;
            }
            if (data?.instagram_content && data.instagram_content.length > 1) {
              body.instagram = data.instagram_content;
            }
            if (data?.linkedin_content && data.linkedin_content.length > 1) {
              body.linkedin = data.linkedin_content;
            }
            
            // Trigger GitHub action and post on social media
            triggerGitHubAction();
            JobService.postSocial(body); // Pass the body object to postSocial
            const isRedirectToListingJob = true; // or false, depending on your logic
            const assist = true; // or false, depending on your logic
            getAllCandidates(jobData, isRedirectToListingJob, assist);
          } else {
            if (jobData?.listings) {
              handleUpgrade({ data: { id: res.id } });
            }
          }
        }
      })
      .finally(() => {
        dispatch(getJobList());
        dispatch(getSetting());
        dispatch(setLoading(false));
      });
  };
  

  const triggerGitHubAction = async () => {
    const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
    const token = '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';

    const payload = {
      event_type: 'listings',
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.github.v3+json',
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(githubApiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('GitHub Actions trigger failed');
      }
    } catch (error) {
      console.error('Error triggering GitHub Actions:', error);
    }
  };


  const handleCloseClient = () => {
    setIsClient(false);
  };

  const handleOpenAddProjectForm = (item) => {
    setIsProject(true);
    setIsClient(false);
  };

  const handleCloseProject = () => {
    setIsProject(false);
  };
  

  const getAllCandidates = async (job, isRedirectToListingJob, assist) => {
    if (!job?.id) return;
  
    dispatch(setLoading(true));
  
    const weights = {
      title: 10,
      description: 20,
      responsibilities: 40,
      technical_skills: 30,
    };
  
    try {
      const response = await fetch('https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/headhunter/rankings', {
        method: 'POST',
        body: JSON.stringify({
          job_id: String(job.id),
          weights: weights,
          request_type: isRedirectToListingJob ? "next_ten" : "next_ten",
          request_typeNumber: 0,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
  
      // Check if the response body is a valid JSON
      let res;
      try {
        res = JSON.parse(json.body);
        // console.log(res)
      } catch (err) {
        return;
      }
  
      if (!res || res.length === 0) {
        return; // Stop further execution if no candidates are found
      }
      
      // Send offers to all candidates found in the API response
      for (const candidate of res) {
        const adjustedScore = String(Math.min(candidate.scores * 4, 0.99));
        const dataSubmit = {
          job: job.id,
          contractor: candidate.user,
          employer: user?.id,
          offered: false,
          scores: adjustedScore,
        };
  
        try {
          await JobService.postJobOffer(dataSubmit);
        } catch (err) {
          console.error('Error sending job offer:', err);
          dispatch(setErrorMess('Error sending job offer.'));
        }
      }
      dispatch(getAllCandidateList());
    } catch (error) {
      console.error('Error fetching candidates:', error);
      dispatch(setErrorMess('Error fetching candidates.'));
    } finally {
      dispatch(setLoading(false));
    }
  };
  
  

  useEffect(() => {
    if (jobsLoaded) {
      const fetchAllOffers = async () => {
        // dispatch(setLoading(true));
        try {
          const [offersResponse, otherOffersResponse] = await Promise.all([
            JobService.getAllOffers(),
            JobService.getAllOffersOther()
          ]);

          if (!offersResponse || !otherOffersResponse) {
            throw new Error('Invalid response from API');
          }
 
          const combinedOffers = {
            interviews: [
              ...(offersResponse.interviews || []),
              ...(otherOffersResponse.interviews || [])
            ],
            jobOffers: [
              ...(offersResponse.jobOffers || []),
              ...(otherOffersResponse.jobOffers || [])
            ]
          };

          setData(prevData => {
            if (!prevData || prevData.length === 0) {
              return prevData;
            }

            const updatedData = prevData.map(job => {
              const jobId = job.id ? job.id.toString() : '';

              if (!jobId) {
                return job;
              }

              const jobInterviews = combinedOffers.interviews.filter(offer => offer.job && offer.job.toString() === jobId);
              const jobOffersOnly = combinedOffers.jobOffers.filter(offer => offer.job && offer.job.toString() === jobId);
              const candidatesOfJob = offersResponse.concat(otherOffersResponse).filter(offer => offer.job && offer.job.toString() === jobId);

              return {
                ...job,
                candidatesOfJob: candidatesOfJob,
                jobOffers: jobOffersOnly,
                interviews: jobInterviews,
              };
            });

            return updatedData;
          });
        } catch (error) {
          console.error('Error fetching all offers:', error);
          dispatch(setErrorMess('Error fetching all offers.'));
        } finally {
          // dispatch(setLoading(false));
        }
      };

      fetchAllOffers();
    }
  }, [jobsLoaded]);

  const formatNumber = (num) => {
    if (num == null) return '0';
    if (num >= 1000 && num < 10000) {
      return (num / 1000).toFixed(1) + 'k';
    } else if (num >= 10000) {
      return Math.floor(num / 1000) + 'k';
    }
    return num.toString();
  };

  const handleEditClick = (job) => {
    setJobToEdit(job);
    setIsEditMode(true);
    setIsAddMode(false);
    setIsProject(false);
    setIsClient(false);
  };

  const handleAddClick = () => {
    setJobToEdit(null);
    setIsEditMode(false);
    setIsAddMode(true);
    setIsProject(false);
    setIsClient(false);
  };

  const handleCloseEdit = () => {
    setJobToEdit(null);
    setIsEditMode(false);
    setIsAddMode(false);
    
  };

  const JobCard = ({ job }) => {
    const longListCount = (job.candidatesOfJob || [])
    .filter(candidate => (!candidate.rejected && !candidate.revoked)
                          && !candidate.offered
                          && !(job.interviews || []).some(interview => interview.job === job.id && interview.contractor === candidate.contractor)
                          && !(job.jobOffers || []).some(offer => offer.job === job.id && offer.contractor === candidate.contractor))
    .length;
  
  const shortListCount = (job.candidatesOfJob || [])
    .filter(candidate => (!candidate.rejected && !candidate.revoked)
                          && candidate.offered
                          && !(job.interviews || []).some(interview => interview.job === job.id && interview.contractor === candidate.contractor)
                          && !(job.jobOffers || []).some(offer => offer.job === job.id && offer.contractor === candidate.contractor))
    .length;
    const interviewCount = (job.interviews || []).length;
    const offerCount = (job.jobOffers || []).length;
  
    const formatSalaryOrRate = (salary, rate) => {
      if (salary) {
        return `$${salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per annum`;
      } else if (rate) {
        return `$${rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} per hour`;
      }
      return '';
    };
  
    const handleFindClick = () => {
      const isRedirectToListingJob = true; // or false, depending on your logic
      const assist = true; // or false, depending on your logic
      getAllCandidates(job, isRedirectToListingJob, assist);
    };

    return (
      <div className={classes.jobContainer}>
        <div className={classes.jobHeader}>
          <div className={classes.jobInfo}>
            <div className={classes.jobTitle}>{job.title}</div>
            <div className={classes.jobSubTitle}>
              {formatSalaryOrRate(job.maxSalary, job.maxRate)} | {job.city} {job.country}
            </div>
          </div>
          <div className={classes.jobDetails}>
            <div>
              <div className={classes.candidateCircle}>{formatNumber(job.headhunterAmount)}</div>
              <div>Identified</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{longListCount}</div>
              <div>Long List</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{shortListCount}</div>
              <div>Short List</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{interviewCount}</div>
              <div>Interview</div>
            </div>
            <div>
              <div className={classes.candidateCircle}>{offerCount}</div>
              <div>Offer</div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <NavLink to={`/employer/admin/myjobs/${job.id}`}>
            <Button color="primary" onClick={handleFindClick}>Manage</Button>
          </NavLink>
          {/* <Button color="primary" onClick={handleFindClick}>Find</Button> */}
          <Button color="secondary" onClick={() => handleEditClick(job)}>Edit</Button>
        </div>
      </div>
    );
  };
  

  return (
    <Fragment>
      <Container className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.title}>Create an Opportunity</div>
          <p>
            Start your process of creating a job opportunity, search millions
            of potential candidates and choose to make it public or private. If
            you have a project in mind, Start a new project and let Necta create
            multiple job opportunities for you based on your needs.
          </p>
          <div className={classes.buttons}>
          {/* {(user.role === 7) && (
          <Button
                          color='success'
                          size='sm'
                          onClick={() => handleOpenAddClientForm(setting)}
                          className={classes.btnContent}
                        >
                          <i className='now-ui-icons ui-1_simple-add mr-1' /> Clients
                        </Button>
          )} */}
            
            <Button
                          color='secondary'
                          size='sm'
                          onClick={() => handleOpenAddProjectForm(setting)}
                          className={classes.btnContent}
                        >
                          Start New Project</Button>
                          <Button color="primary" onClick={handleAddClick}>Add New Opportunity</Button>
          </div>
        </div>

        {
        isClient ? (
          <Client onClose={handleCloseClient} />
        ) : isProject ? (
          <CreateProject onClose={handleCloseProject} />
        ) : (
          isEditMode || isAddMode ? (
              <AddNewJob
                isOpen={isEditMode || isAddMode}
                onClose={handleCloseEdit}
                itemEdit={isEditMode ? jobToEdit : null}
                handleAddJob={(data) => {
                  handleAddJob(data);  // Only pass the data argument
                  handleCloseEdit();
                }}
                handleUpdateJob={(data, id) => {
                  handleUpdateJob(data, id);  // Only pass the data and id arguments
                  handleCloseEdit();
                }}
              />
          ) : (
            data.map((job) => (
              <JobCard key={job.id} job={job} />
            ))
          )
        )
      }
      </Container>
      <Modal
          size="lg"
          centered
          isOpen={showSignTermModal}
          toggle={handleShowSignTermModal}
          className={classes.termModal}
        >
        <SignOrgTerm
          onCloseSignTermModal={handleShowSignTermModal}
          handleAcceptTerm={handleAcceptTerm}
        />
      </Modal>
    </Fragment>
  );
});

export default Myjobs;
