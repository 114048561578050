import { memo, useEffect, useMemo, useState } from 'react';
import { Form, Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, Progress, Table } from 'reactstrap';
import classes from './styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/Common/CustomInput';
import CustomMultiSelect from 'components/Common/CustomMultiSelect';
import CustomSelect from 'components/Common/CustomSelect';
import CustomTextarea from 'components/CustomTextarea';
import CustomHtml from 'components/CustomHtml';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMess, setLoading, setSuccessMess } from 'redux/reducers/Status/actionTypes';
import SearchLocationInput from 'components/Common/GoogleAddress';
import CustomTagsInput from 'components/Common/CustomTagsInput';
import { JobTypes, NumberOfExperience } from 'models/Employer/Listings/Dashboard';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import ArchiveWarningModal from './PopupDeleteWarning';
import { JobService } from 'services/Employer/Listing/Job';
import { ReducerType } from 'redux/reducers';
import { setJobListReducer } from 'redux/reducers/Employer/actionTypes';
import CustomSlider from 'components/Common/CustomSlider';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import moment from 'moment';
import { VisaTypes } from 'models/Contractor/NewMember/MojCheck';
import { downloadJD } from 'components/docxtemplater/downloadJD';
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';
import CustomCheckbox from 'components/Common/CustomCheckbox';
import JobDetailsForm from './stages/offering';
import JobDescriptionForm from './stages/description';
import ReviewComponent from './stages/preview';
import OutreachForm from './stages/outreach';
import JobBoardForm from './stages/jobBoards'
import QuestionForm from './stages/questions'
import { jobBoards } from './stages/jobBoardData'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PaymentModal from './stages/paymentModal';

function processSelectedVisaTypes(selectedOptions) {
  if (!selectedOptions || selectedOptions.length === 0) {
    return null;
  }

  const hasCitizen = selectedOptions.some((option) => option.value === "NZAUSCitizen");
  const hasPermanentResident = selectedOptions.some((option) => option.value === "NZPermanentVisa");
  const hasWorkVisa = selectedOptions.some((option) => option.value === "NZWorkVisa");
  const hasOther = selectedOptions.some((option) => option.value === "Other");

  if (hasOther) {
    return "Other";
  } else if (hasWorkVisa) {
    return "NZWorkVisa";
  } else if (hasPermanentResident) {
    return "NZPermanentVisa";
  } else if (hasCitizen) {
    return "NZAUSCitizen";
  } else {
    return null;
  }
}

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  onClose: () => void;
  isOpen: boolean;
  itemEdit: any;
  handleAddJob: (data) => void;
  handleUpdateJob: (data, id) => void;
}

interface IClientOption {
  value: string;
  label: string;
}

const stages = ['Offerings', 'Description', 'Outreach', 'Upgrades', 'Review'];





const AddNewJob = memo(
  ({ onClose, isOpen, itemEdit, handleAddJob, handleUpdateJob }: Props) => {
    const dispatch = useDispatch();
    const { setting, jobList, jobTemplatesList } = useSelector((state: ReducerType) => state.employer);
    // console.log("HERE AGAIN", jobList)
    const { user } = useSelector((state: ReducerType) => state.user);
    const [warningModalOpen, setWarningModalOpen] = useState({ isOpen: false, item: null });
    const [selectedClient, setSelectedClient] = useState(null);
    const [isPercentage, setIsPercentage] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const [isPayrollIncluded, setIsPayrollIncluded] = useState(false);
    // const [clients, setClients] = useState([]);
    const [error, setError] = useState(null);
    const [selectedClientObject, setSelectedClientObject] = useState(null);
    const [fullClientDetails, setFullClientDetails] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);
    const [step, setStep] = useState(0); // State to track the current step
    const isLastStep = step === stages.length - 1;
    const isFirstStep = step === 0;
    const [isMobile, setIsMobile] = useState(false);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [jobBoardsToPayFor, setJobBoardsToPayFor] = useState([]);
    const [finalDataToSubmit, setFinalDataToSubmit] = useState(null);



    

    useEffect(() => {
      // Function to check if it's a mobile device
      const checkIfMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Set your mobile screen width breakpoint (768px in this case)
      };
  
      // Check on component mount
      checkIfMobile();
  
      // Add event listener to handle window resize
      window.addEventListener('resize', checkIfMobile);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', checkIfMobile);
      };
    }, []);

    const [data, setData] = useState({
      title: '',
      company: '',
      jobType: null,
      hourlyRate: '',
      startDate: '',
      policy: null,
      numberOfExperience: null,
      skill: [],
      description: '',
      responsibilities: '',
      outreach: [],
      questions: [],
      jobBoards: []
    });

    // useEffect(() => {
    //   console.log(jobTemplatesList); // This should show an array of templates
    // }, [jobTemplatesList]);



    const jobTemplateOptions = useMemo(() => {
      return jobTemplatesList
        .filter((template) => !template.isArchived) // Filter out archived templates
        .map((template) => {
          const jobType = template.isContract ? 'Contract' : template.isPermanent ? 'Permanent' : template.isFixed ? 'Fixed' : 'Unknown';
          return {
            value: template, // Store the entire template object here
            label: `${template.title} | ${jobType} | ${template.city || 'Unknown City'}`,
          };
        });
    }, [jobTemplatesList]);

    const handleLoadTemplate = () => {
      if (selectedTemplate) {
        const selectedJobTemplate = selectedTemplate; // Use the selected template directly

        if (selectedJobTemplate) {
          // Populate the form fields with the selected template's data
          reset({
            title: selectedJobTemplate.title,
            jobType: selectedJobTemplate.isContract ? JobTypes[0] : selectedJobTemplate.isPermanent ? JobTypes[1] : selectedJobTemplate.isFixed ? JobTypes[2] : null,
            company: selectedJobTemplate.companyName,
            address: selectedJobTemplate.city ? `${selectedJobTemplate.city}, ${selectedJobTemplate.region}, ${selectedJobTemplate.country}` : `${selectedJobTemplate.region}, ${selectedJobTemplate.country}`,
            hourlyRate: selectedJobTemplate.maxRate || selectedJobTemplate.maxSalary || 0,
            startDate: selectedJobTemplate.startDate ? new Date(selectedJobTemplate.startDate) : null,
            policy: PreferencePolicyTypes.find((policy) => policy.value === selectedJobTemplate.workPolicy),
            numberOfExperience: NumberOfExperience.find((experience) => experience.value === selectedJobTemplate.experienceYears),
            skill: selectedJobTemplate.technicalSkills || [],
            description: selectedJobTemplate.description || "",
            responsibilities: selectedJobTemplate.responsibilities || "",
            private: selectedJobTemplate.private || false,
            listings: selectedJobTemplate.listings || true,

          });

          // Close modal
          setTemplateModalOpen(false);
        }
      }
    };

    const handleCheckboxChange = (isChecked: boolean) => {
      setIsPercentage(isChecked);
    };

    const handlePayrollCheckboxChange = (isChecked: boolean) => {
      setIsPayrollIncluded(isChecked);
    };

    const [isListings, setIsListings] = useState(false);
    const handleListingsChange = (isChecked: boolean) => {
      setIsListings(isChecked);
    };

    const [isPrivate, setIsPrivate] = useState(false);
    const handlePrivateChange = (isChecked: boolean) => {
      setIsPrivate(isChecked);
    };

    const schema = useMemo(() => {
      return yup.object().shape({
        id: yup.number(),
        title: yup.string().min(2, 'At least 2 characters').max(64, 'At least 64 characters').required('This field is required'),
        email: yup.string().email('Please enter a valid email').nullable().notRequired(),
        jobType: yup.object().nullable().required('This field is required'),
        company: yup.string().required('This field is required'),
        hourlyRate: yup.number().typeError('Please enter a valid value'),
        startDate: yup.date().typeError('Please enter a valid date').required('This field is required'),
        policy: yup.object().nullable().required('This field is required'),
        numberOfExperience: yup.object().nullable().required('This field is required'),
        skill: yup.array().of(yup.string()),
        description: yup.string().max(5000, 'Maximum 5000 characters').required('This field is required'),
        responsibilities: yup.string().max(5000, 'Maximum 5000 characters').required('This field is required'),
        address: yup.string().required('This field is required'),
        country: yup.string(),
        city: yup.string(),
        region: yup.string(),
      });
    }, []);

    const { register, handleSubmit, formState: { errors }, reset, setValue, watch, control, trigger } = useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
    });

    const jobTypeField = watch('jobType');

    const [htmlContent, setHtmlContent] = useState('');

    const handleContentChange = (content) => {
      setHtmlContent(content);
    };


    const handleNext = async () => {
      let isStepValid = true;
    
      // Only validate the fields for the current step
      if (step === 0) {
        isStepValid = await trigger(['title', 'company', 'jobType']); // Add fields that belong to the first step
      } else if (step === 1) {
        isStepValid = await trigger(['description', 'responsibilities']); // Add fields that belong to the second step
      } 
      // Add more conditions for other steps
    
      if (!isStepValid) {
        return; // Prevent progressing to the next step if validation fails
      }
    
      const currentStepData = watch(); // Capture the current step's form data
    
      // Ensure city, region, and country are captured
      let city = watch('city');
      let region = watch('region');
      let country = watch('country');
    
      // Fallback to existing values from itemEdit if they exist and are not changed
      if (!city && itemEdit?.city) {
        city = itemEdit.city;
      }
      if (!region && itemEdit?.region) {
        region = itemEdit.region;
      }
      if (!country && itemEdit?.country) {
        country = itemEdit.country;
      }
    
      // Get questions from the form data
      const questionsData = watch('questions') || [];
    
      // Get job boards from the form data (including agreement status)
      const jobBoardsData = watch('jobBoards') || [];
    
      // Update the data state with the new information
      setData((prevData) => ({
        ...prevData,
        ...currentStepData, // Merge current step data
        city, // Merge city
        region, // Merge region
        country, // Merge country
        outreach: [
          { name: 'Facebook', content: watch('facebook_content') },
          { name: 'Twitter', content: watch('twitter_content') },
          { name: 'LinkedIn', content: watch('linkedin_content') },
          { name: 'Instagram', content: watch('instagram_content') },
        ], // Merge outreach data
        questions: questionsData, // Merge questions data
        jobBoards: jobBoardsData, // Merge job boards data
      }));
    
      if (!isLastStep) {
        setStep((prev) => prev + 1);
      }
    };


    const handleBack = () => {
      if (!isFirstStep) {
        setStep(prev => prev - 1);
      }
    };

    const handlePaymentProceed = () => {
      // Integrate Stripe payment processing here
      // If payment is successful:
      proceedToSaveJob(finalDataToSubmit);
      setPaymentModalOpen(false);
    };

    const handlePaymentCancel = () => {
      // Set the job boards that were changed back to false
      const updatedJobBoards = finalDataToSubmit.jobBoards.map((board) => {
        const boardId = board.name.toLowerCase().replace(/\s/g, '');
        if (jobBoardsToPayFor.includes(boardId)) {
          return {
            ...board,
            agreement: false,
          };
        }
        return board;
      });
      finalDataToSubmit.jobBoards = updatedJobBoards;
      proceedToSaveJob(finalDataToSubmit);
      setPaymentModalOpen(false);
    };

    const proceedToSaveJob = (data) => {
      const selectedVisaType = processSelectedVisaTypes(data.visaType);
      const rate = data?.hourlyRate;
      let salary = 0;
      if (data?.jobType?.id === 1) {
        salary = rate === undefined ? 30 : (rate > 300 ? 300 : rate);
      } else {
        salary = (rate === undefined || rate < 40000) ? 40000 : rate;
      }
      let chargeAmount;
      if (isPercentage) {
        chargeAmount = data.percentageOffered;
      } else {
        chargeAmount = data.fixedPriceOffer;
      }
      const clientData = {
        firstName: chosenRawClientData?.firstName,
        lastName: chosenRawClientData?.lastName,
        email: chosenRawClientData?.email,
        phone: chosenRawClientData?.phone,
        company: chosenRawClientData?.company,
        fixedPrice: !isPercentage,
        chargeAmount: chargeAmount,
        payrollInclusive: isPayrollIncluded,
        stage: "lead"
      };
    



      const submitJobData = (clientId) => {
        const jobData = {
          title: data?.title,
          client: clientId,
          isContract: data?.jobType?.id === 1,
          isPermanent: data?.jobType?.id === 2,
          isFixed: data?.jobType?.id === 3,
          organization: setting?.idOrganisation, 
          companyName: data?.company || setting?.name,
          city: data?.city,
          country: data?.country,
          region: data?.region,
          maxRate: data?.jobType?.id === 1 ? salary : null,
          maxSalary: data?.jobType?.id !== 1 ? salary : null,
          startDate: data?.startDate?.toISOString(),
          workPolicy: data?.policy?.value,
          technicalSkills: data?.skill,
          experienceYears: data?.numberOfExperience ? NumberOfExperience[data.numberOfExperience.id - 1].value : null,
          description: data?.description,
          responsibilities: data?.responsibilities,
          visaType: selectedVisaType,
          listings: isListings,
          private: isPrivate,
          headhunter: setting?.headHunter,
          trademe: data?.jobBoards?.find((board) => board.name === 'TradeMe')?.agreement || false,
          linkedin: data?.jobBoards?.find((board) => board.name === 'LinkedIn')?.agreement || false,
          seek: data?.jobBoards?.find((board) => board.name === 'Seek')?.agreement || false,
          dogoodjobs: data?.jobBoards?.find((board) => board.name === 'Do Good Jobs')?.agreement || false,
        };
              // Combine both jobData and clientData
        const allData = {
          jobData,
          data, // Any other additional data
        };
        // console.log("here", data)
        if (!itemEdit) {
          handleAddJob(allData);
        } else {
          handleUpdateJob(allData, itemEdit?.id);
        }
      };
    
      if (selectedClientObject?.value === "new") {
        fetch('https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/assistcreateprofile/user', {
            method: 'POST',
            body: JSON.stringify({
                email: clientData?.email, 
                username: clientData?.email
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(userResponse => {
            const clientDataWithUsername = {
                ...clientData,
                username: userResponse.userid 
            };
            return JobService.postClient(clientDataWithUsername);
        })
        .then(response => {
            const newClientId = response.id;
            submitJobData(newClientId);
        })
        .catch(error => console.error('Error:', error));
      } else if (selectedClientObject) {
        const clientId = parseInt(selectedClientObject.value);
        JobService.putClient(clientData, clientId)
          .then(response => submitJobData(clientId))
          .catch(error => console.error('Error updating client:', error));
      } else {
        submitJobData(selectedClientObject ? selectedClientObject.value : null);
      }
    
      onClose();
    }

    const onSubmit = (data) => {
      // console.log(data)
      if (isLastStep) {

      const initialJobBoards = {
        trademe: itemEdit?.trademe || false,
        linkedin: itemEdit?.linkedin || false,
        seek: itemEdit?.seek || false,
        dogoodjobs: itemEdit?.dogoodjobs || false,
      };

      // Get current job board agreements from data.jobBoards
      const currentJobBoards = {};
      data.jobBoards.forEach((board) => {
        const boardId = board.name.toLowerCase().replace(/\s/g, '');
        currentJobBoards[boardId] = board.agreement;
      });

      // Check if any job boards have changed from false to true
      const jobBoardsChangedToAgreed = [];
      for (const boardId in currentJobBoards) {
        if (!initialJobBoards[boardId] && currentJobBoards[boardId]) {
          jobBoardsChangedToAgreed.push(boardId);
        }
      }

      if (jobBoardsChangedToAgreed.length > 0) {
        // Trigger payment modal
        setPaymentModalOpen(true);
        setJobBoardsToPayFor(jobBoardsChangedToAgreed);
        setFinalDataToSubmit(data);
      } else {
        // No payment needed, proceed with saving
        proceedToSaveJob(data);
      }
    } else {
      handleNext();
    }
  };



    useEffect(() => {
      if (selectedClientObject && selectedClientObject.value === 'new') {
        setFullClientDetails(null);
      }
    }, [selectedClientObject]);

    const [rawClientData, setRawClientData] = useState([]);
    const [chosenRawClientData, setChosenRawClientData] = useState(null);
    const updateChosenRawClientData = (updatedData) => {
      setChosenRawClientData({ ...chosenRawClientData, ...updatedData });
    };
    
    useEffect(() => {
      // JobService.getClient()
      // .then(data => {
      //     setRawClientData(data);
      //     const mappedClients = data.map(client => ({
      //       value: client.id.toString(),
      //       label: `${client.firstName} ${client.lastName} | ${client.company}`
      //   }));
      //     setClients(mappedClients);
      // })
      // .catch(error => {
      //     console.error("Error fetching clients:", error);
      // });
      if (!isOpen) {
        reset({
          title: '',
          jobType: null,
          company: setting?.name,
          region: '',
          city: '',
          country: '',
          address: '',
          hourlyRate: '',
          startDate: null,
          policy: null,
          numberOfExperience: null,
          skill: [],
          description: '',
          responsibilities: '',
          visaType: null,
        });
      } else {
        if (itemEdit) {
          // let selectedClientObject;

          // if (itemEdit?.client) {
          //     selectedClientObject = modifiedClients.find(client => client.value === itemEdit.client.toString());
          // } else {
          //     selectedClientObject = null;
          // }

          reset({
            id: itemEdit?.id,
            title: itemEdit?.title,
            jobType: itemEdit?.isContract ? JobTypes[0] : itemEdit?.isPermanent ? JobTypes[1] : itemEdit?.isFixed ? JobTypes[2] : null,
            company: itemEdit?.companyName,
            email: itemEdit?.email,
            address: itemEdit?.region && itemEdit?.country ? itemEdit?.city ? `${itemEdit?.city}, ${itemEdit?.region}, ${itemEdit?.country}` : `${itemEdit?.region}, ${itemEdit?.country}` : '',
            hourlyRate: itemEdit?.maxSalary || itemEdit?.maxRate,
            startDate: new Date(itemEdit?.startDate),
            policy: itemEdit?.workPolicy === 'office' ? PreferencePolicyTypes[0] : itemEdit?.workPolicy === 'mixed' ? PreferencePolicyTypes[1] : itemEdit?.workPolicy === 'all' ? PreferencePolicyTypes[3] : PreferencePolicyTypes[2],
            numberOfExperience: itemEdit?.experienceYears === NumberOfExperience[0].value ? NumberOfExperience[0] : itemEdit?.experienceYears === NumberOfExperience[1].value ? NumberOfExperience[1] : itemEdit?.experienceYears === NumberOfExperience[2].value ? NumberOfExperience[2] : null,
            skill: itemEdit?.technicalSkills?.length ? [...itemEdit.technicalSkills] : [],
            description: itemEdit?.description,
            headhunter: itemEdit?.headhunter,
            responsibilities: itemEdit?.responsibilities,
            listings: itemEdit?.listings,
            private: itemEdit?.private,
            visaType: itemEdit?.visaType === VisaTypes[0].value ? [VisaTypes[0]] : itemEdit?.visaType === VisaTypes[1].value ? [VisaTypes[0], VisaTypes[1]] : itemEdit?.visaType === VisaTypes[2].value ? [VisaTypes[0], VisaTypes[1], VisaTypes[2]] : itemEdit?.visaType === VisaTypes[3].value ? [VisaTypes[0], VisaTypes[1], VisaTypes[2], VisaTypes[3]] : null,
          });
        } else {
          reset({
            company: setting?.name,
          });
        }
      }
    }, [
      dispatch,
      isOpen,
      itemEdit,
      reset,
      setting?.city,
      setting?.country,
      setting?.name,
    ]);

    const handleOpenArchiveModal = (id) => {
      setWarningModalOpen({
        isOpen: true,
        item: id,
      });
    };

    const triggerGitHubAction = async () => {
      const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
      const token = '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'; 

      const payload = {
        event_type: 'listings', 
      };

      const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/vnd.github.v3+json',
        'Content-Type': 'application/json',
      };

      try {
        const response = await fetch(githubApiUrl, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('GitHub Actions trigger failed');
        }
      } catch (error) {
        console.error('Error triggering GitHub Actions:', error);
      }
    };

    const handleArchive = () => {
      dispatch(setLoading(true));
      JobService.archiveJob(itemEdit?.id)
        .then((res) => {
          dispatch(
            setJobListReducer([
              ...jobList.map((item) => (item?.id === res?.id ? res : item)),
            ])
          );
          dispatch(setSuccessMess('Successfully archived job!'));
        })
        .catch((err) => {
          dispatch(setErrorMess(err));
        })
        .finally(() => {
          triggerGitHubAction();
          warningOnClose();
          onClose();
          dispatch(setLoading(false));
        });
    };

    const warningOnClose = () => {
      setWarningModalOpen({
        isOpen: false,
        item: null,
      });
    };

    const options = [
      { id: "1", name: "Option 1" },
      { id: "2", name: "Option 2" },
      { id: "3", name: "Option 3" },
    ];

    const handleButtonClick = () => {
      dispatch(setLoading(true));
      const formFields = [
        'id',
        'title',
        'client',
        'jobType',
        'company',
        'hourlyRate',
        'startDate',
        'policy',
        'numberOfExperience',
        'skill',
        'description',
        'responsibilities',
        'address',
        'country',
        'city',
        'region',
        'visaType',
      ];

      const formData = formFields.reduce((acc, field) => {
        acc[field] = watch(field);
        return acc;
      }, {});

      downloadJD(formData, setting)
        .then(() => {
        })
        .catch((err) => {
          dispatch(setErrorMess(err));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    };

    // const modifiedClients = [
    //   ...clients
    // ];

    // useEffect(() => {
    //   if (itemEdit && itemEdit.client) {
    //     const foundClientObject = modifiedClients.find(client => client.value === itemEdit.client.toString());
    //     setSelectedClientObject(foundClientObject);

    //     const foundClientRaw = rawClientData.find(client => client.id.toString() === itemEdit.client.toString());
    //     setChosenRawClientData(foundClientRaw);
    //   } else {
    //     setSelectedClientObject(null);
    //     setChosenRawClientData(null); 
    //   }
    // }, [itemEdit]);

    useEffect(() => {
      if (chosenRawClientData) {
        if (chosenRawClientData.fixedPrice !== null && selectedClientObject.value !== 'new') {
          setIsPercentage(chosenRawClientData.fixedPrice === false);
        }
        const chargeAmount = chosenRawClientData.chargeAmount != null ? chosenRawClientData.chargeAmount : (isPercentage ? 6.0 : 6000);
        setSliderValue(chargeAmount);

        const payrollInclusive = chosenRawClientData.payrollInclusive != null ? chosenRawClientData.payrollInclusive : false;
        setIsPayrollIncluded(payrollInclusive);
      } else {
        setSliderValue(isPercentage ? 6.0 : 6000); 
      }
    }, [chosenRawClientData, isPercentage]);

    return (
      <>
          {/* Enhanced Progress Bar */}
          <div className={classes.progressContainer}>
            {/* Stage Labels */}
            <div className={classes.stageLabels}>
              {stages.map((stage, index) => (
                <span
                  key={index}
                  className={`${classes.stageLabel} ${index <= step ? classes.activeStage : ''}`}
                >
                  {stage}
                </span>
              ))}
            </div>

            {/* Progress Bar */}
            <Progress
              value={(step / (stages.length - 1)) * 100}
              className={classes.progressBar}
              color="success"
            >
              Step {step + 1} of {stages.length}: {stages[step]}
            </Progress>
          </div>
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>

          <Form onSubmit={handleSubmit(onSubmit)}>
            
          <div className={classes['title-container']}>
  {/* Back or Cancel Button */}
  <Button
    size="md"
    color="default"
    type="button"
    className={classes.btn}
    onClick={onClose}
  >
    {itemEdit ? "Back" : "Cancel"}
  </Button>

  {/* Title */}
  <p className={classes.title}>
    {!itemEdit ? (isMobile ? 'New' : 'Add New Opportunity') : (isMobile ? 'Edit' : 'Edit Opportunity')}
  </p>

  {/* Load Template Button, only visible if not editing */}
  {!itemEdit && jobTemplatesList.filter(template => !template.isArchived).length > 0 && (
    <Button
      size="md"
      color="primary"
      type="button"
      className={classes.btn}
      onClick={() => setTemplateModalOpen(true)}
    >
      {isMobile ? 'Load' : 'Load Template'}
    </Button>
  )}

  {/* Archive Button, only visible when editing */}
  {itemEdit && (
    <Button
      size='md'
      color='danger'
      type='button'
      className={classes.btnDelete}
      onClick={() => handleOpenArchiveModal(itemEdit?.id)}
    >
      Archive
    </Button>
  )}
</div>

            
            <div className={classes.content}>
            {step === 0 && (
              <JobDetailsForm
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  itemEdit={itemEdit}
                  register={register}
                />
              )}
              {step === 1 && (
                <JobDescriptionForm
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  itemEdit={itemEdit}
                  register={register}
                />
              )}
              {step === 2 && (
                <OutreachForm
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  itemEdit={itemEdit}
                  register={register}
                  data={data}
                />
              )}

              {/* {step === 3 && (
                <QuestionForm
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                itemEdit={itemEdit}
                register={register}
                data={data}
              />
              )} */}
              {step === 3 && (
                <JobBoardForm
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  itemEdit={itemEdit}
                  register={register}
                  data={data}
                />
              )}
              {step === 4 && (
                  <ReviewComponent
                  data={data}
                  watch={watch}
                  setValue={setValue}
                  itemEdit={itemEdit}
                  onListingsChange={handleListingsChange} // Pass the function to handle listings change
                />
              )}
              {/* {user?.role !== 2 && ( */}

              {/* )} */}

            </div>

          </Form>
                  {/* Bottom Navigation */}
                  <div className={classes.bottomNavBar}>
                  {step !== 0 && (
          <Button onClick={handleBack} disabled={isFirstStep}>
            Back
          </Button>
                  )}
          <Button
            color="primary"
            onClick={() => {
              if (isLastStep) {
                handleSubmit(onSubmit)(); // Make sure to call handleSubmit with onSubmit function
              } else {
                handleNext(); // Continue to the next step if not the last
              }
            }}
          >
            {isLastStep ? (itemEdit ? 'Update' : 'Submit') : 'Next'}
          </Button>
        </div>
        </CardBody>
        <ArchiveWarningModal
          isOpen={warningModalOpen.isOpen}
          onClose={warningOnClose}
          handleArchive={handleArchive}
        />
      </Card>
        <Modal isOpen={isTemplateModalOpen} toggle={() => setTemplateModalOpen(!isTemplateModalOpen)} centered>
          <ModalHeader toggle={() => setTemplateModalOpen(!isTemplateModalOpen)}>Load Job Template</ModalHeader>
          <ModalBody>
  <Input
    type="select"
    onChange={(e) => {
      const selectedOption = jobTemplateOptions.find(option => option.value.id === parseInt(e.target.value));
      setSelectedTemplate(selectedOption ? selectedOption.value : null);
    }}
  >
    <option value="">Select Job Template</option>
    {jobTemplateOptions.map((option) => (
      <option key={option.value.id} value={option.value.id}>
        {option.label}
      </option>
    ))}
  </Input>
</ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={handleLoadTemplate}>
              Load Template
            </Button>
            <Button
                size="md"
                color="default"
                type="button"
                className={classes.btn}
                onClick={step === 0 ? onClose : handleBack}
              >
                {step === 0 ? "Cancel" : "Back"}
              </Button>
          </ModalFooter>
        </Modal>
          {/* Payment Modal */}
          {paymentModalOpen && (
        <PaymentModal
          isOpen={paymentModalOpen}
          jobBoardsToPayFor={jobBoardsToPayFor}
          onProceed={handlePaymentProceed}
          onCancel={handlePaymentCancel}
        />
      )}
      </>
    );
  }
);

export default AddNewJob;
